import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.38.1_babel-p_d0cfa705cc49cd7cc663d7febc81de59/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.38.1_babel-p_d0cfa705cc49cd7cc663d7febc81de59/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.38.1_babel-p_d0cfa705cc49cd7cc663d7febc81de59/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.38.1_babel-p_d0cfa705cc49cd7cc663d7febc81de59/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.38.1_babel-p_d0cfa705cc49cd7cc663d7febc81de59/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.38.1_babel-p_d0cfa705cc49cd7cc663d7febc81de59/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.38.1_babel-p_d0cfa705cc49cd7cc663d7febc81de59/node_modules/next/dist/lib/metadata/metadata-boundary.js");
